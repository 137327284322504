/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
export function getDirectionsUrlCustom(profile, key = 'c_addressLine1') {
  const addr = profile[key];
  if (!addr) {
    return '';
  }

  // const line2 = addr.line2 ? ` ${addr.line2},` : ``;
  const line2 = profile.c_addressLine2 ? ` ${profile.c_addressLine2},` : ``;
  const rawQuery = `${profile.c_addressLine1},${line2} ${profile.c_addressCity}, ${profile.c_postalCode} `;
  const query = encodeURIComponent(rawQuery);

  let url = `https://www.google.com/maps/search/?api=1&query=${query}&output=classic`;

  if (profile.googlePlaceId) {
    url += `&query_place_id=${profile.googlePlaceId}`;
  }

  return url;
}

export function addressCustom(profile) {

  const line1 = profile.c_addressLine1 ? profile.c_addressLine1 : '';
  const line2 = profile.c_addressLine2 ?  profile.c_addressLine2 : '';
  const postalCode = profile.c_postalCode ? profile.c_postalCode : '';
  const city = profile.c_addressCity ? profile.c_addressCity :  '';

  
  let text = [line1, line2].filter(Boolean).join(", ");
  let text2 = [text, [postalCode, city].filter(Boolean).join(" ")].filter(Boolean).join(", ");

  if (profile.c_addressLine1 || profile.c_addressLine2 || profile.c_addressCity || postalCode) { 
    return text2;
  }
}